import APIError from '../errors/APIError'
import update from 'immutability-helper'
import { Auth } from 'firebase/auth'
import {
  PracticeSignupForm,
  NurseSignupForm,
  MakeRequestParams,
  AddJobForm,
  DocumentForm,
  NurseProfileForm,
  PracticeProfileForm,
  EditJobForm,
  TakeJobForm,
  VerifyDocumentForm,
  NurseSearchOptions,
  NurseEnableForm,
  PracticeEnableForm,
  UserForm,
  CustomEmailForm,
  NurseSearchForm,
  PracticeSignupResult,
  JobSearchForm,
  JobEmploymentUpdateForm,
  JobEmploymentCancelForm,
  QueuedEmailSearchForm,
  JobCancelForm,
  TimeSheetChangeSearchForm,
  TimeSheetChangeForm,
  NurseDocumentSearchForm,
  JobEmploymentSearchForm,
  NursePaygradeStats,
  EstimateCost,
  EstimateCostForm,
  AffiliateTransactionSearchForm,
  PracticeSearchForm,
  SubmitReviewForm,
  BlacklistedNurseSearchForm,
  BlacklistNurseForm,
  InvitationSearchForm,
  InvitationForm,
  AcceptInvitationForm,
  PracticeEmploymentSearchForm,
  PracticeEmploymentForm,
  PracticeGroupSearchForm,
  PracticeGroupPracticeForm,
  PaymentMethodSearchForm,
  NurseRightToWorkDocumentLink,
  ChargeNurseLateCancelFeeForm,
  PracticeGroupTransactionSearchForm,
  NurseLocationReportItem,
  PracticeGroupMemberSearchForm,
  AffiliateSearchForm,
  NurseWaitlistForm,
  PracticeReportItem,
  PracticeReportSearchForm,
  NurseLocationReportSearchForm,
  BillingAccountSearchForm,
  BillingAccountForm,
  BillingAccountLinkForm,
  SetupBillingAccountResponse,
  InvoiceSearchForm,
  RefundSearchForm,
  RefundForm,
  AdditionalChargeSearchForm,
  AdditionalChargeForm,
  InvoiceLateFeeSearchForm,
  IssueInvoiceLateFeeForm,
  ChartSearchForm,
  JobChartDataItem,
  MatchChartDataItem,
  EarningsChartDataItem,
  JobCancellationChartDataItem,
  UserSearchForm,
  UserClaim,
  NurseTierChartDataItem,
  MatchFulfillmentSpeedChartDataItem,
  MatchNurseTierChartDataItem,
  JobUrgencyChartDataItem,
  MatchCorporateChartDataItem,
  PracticeSignupStageChartDataItem,
  NurseMapPointSearchForm,
  CashBalanceTransactionSearchForm
} from '../types/interfaces'
import PracticeSignupError from '../errors/PracticeSignupError'
import NurseSignupError from '../errors/NurseSignupError'
import { plainToClass } from 'class-transformer'
import AddJobError from '../errors/AddJobError'
import Nurse from './Nurse'
import Practice from './Practice'
import DocumentError from '../errors/DocumentError'
import NurseProfileError from 'errors/NurseProfileError'
import PracticeProfileError from 'errors/PracticeProfileError'
import { fromMilesToKm } from './helpers'
import Job from './Job'
import JobEmployment from './JobEmployment'
import EditJobError from 'errors/EditJobError'
import TakeJobError from 'errors/TakeJobError'
import { NurseTransaction } from './NurseTransaction'
import { NursePayout } from './NursePayout'
import { PracticeTransaction } from './PracticeTransaction'
import VerifyDocumentError from 'errors/VerifyDocumentError'
import Achievement from './Achievement'
import EarningsReport from './EarningsReport'
import SavingsReport from './SavingsReport'
import UserFormError from 'errors/UserFormError'
import UserInfo from './UserInfo'
import NurseDocumentFile from './NurseDocumentFile'
import CustomEmailError from 'errors/CustomEmailError'
import JobEmploymentUpdateError from 'errors/JobEmploymentUpdateError'
import JobEmploymentCancelError from 'errors/JobEmploymentCancelError'
import { QueuedEmail } from './QueuedEmail'
import JobCancelError from 'errors/JobCancelError'
import TimeSheetChange from './TimeSheetChange'
import TimeSheetChangeError from 'errors/TimeSheetChangeError'
import EstimateCostError from '../errors/EstimateCostError'
import Affiliate from 'logic/Affiliate'
import SubmitReviewError from 'errors/SubmitReviewError'
import BlacklistedNurse from 'logic/BlacklistedNurse'
import { buildURLSearchParams } from './helpers'
import Invitation from 'logic/Invitation'
import InvitationFormError from 'errors/InvitationFormError'
import AcceptInvitationFormError from 'errors/AcceptInvitationFormError'
import PracticeMember from 'logic/PracticeMember'
import PracticeEmploymentFormError from 'errors/PracticeEmploymentFormError'
import PracticeGroup from 'logic/PracticeGroup'
import PracticeGroupPracticeError from 'errors/PracticeGroupPracticeError'
import PaymentMethod from 'logic/PaymentMethod'
import ChargeNurseLateCancelFeeError from 'errors/ChargeNurseLateCancelFeeError'
import { PracticeGroupTransaction } from 'logic/PracticeGroupTransaction'
import PracticeGroupMember from 'logic/PracticeGroupMember'
import NurseWaitlistFormError from 'errors/NurseWaitlistFormError'
import { DocumentType } from 'types/types'
import BillingAccount from 'logic/BillingAccount'
import BillingAccountFormError from 'errors/BillingAccountFormError'
import BillingAccountLinkFormError from 'errors/BillingAccountLinkFormError'
import Invoice from 'logic/Invoice'
import Refund from 'logic/Refund'
import RefundFormError from 'errors/RefundFormError'
import AdditionalCharge from 'logic/AddditionalCharge'
import AdditionalChargeFormError from 'errors/AdditionalChargeFormError'
import { DateTime } from 'luxon'
import InvoiceLateFee from 'logic/InvoiceLateFee'
import IssueInvoiceLateFeeError from 'errors/IssueInvoiceLateFeeError'
import NurseMapPoint from './NurseMapPoint'
import CashBalanceTransaction from './CashBalanceTransasction'

const defaultToError = (resp: Response, body: any) => new APIError(resp.status, resp.statusText, body)

class API {
  logRocketURL: string = 'N/A'

  constructor(
    readonly auth: Auth,
    readonly base_url: string) { }

  async makeRequest({
    method, url,
    data = null,
    toError = defaultToError,
    version
  }: MakeRequestParams) {
    const body = data ? JSON.stringify(data) : null
    const authToken = await this.auth.currentUser?.getIdToken()
    const acceptHeader = version !== undefined ? `application/json; version=${version}` : 'application/json'
    const options = {
      method: method,
      body: body,
      headers: {
        'Content-Type': 'application/json',
        'Accept': acceptHeader,
        'Authorization': authToken || '',
        'logrocket': this.logRocketURL
      }
    }

    try {
      const resp = await fetch(this.base_url + url, options)
      const contentType = resp.headers.get('content-type') || 'text/html'
      const result = await (contentType.startsWith('application/json') ? resp.json() : resp.text())

      // return results if no errors
      if (resp.ok) {
        return result
      }

      // handle API Errors
      throw toError(resp, result)
    } catch (e: any) {
      // handle if backend unreachable
      throw e
    }
  }

  async createPractice(data: PracticeSignupForm, dryRun: boolean = true) {
    const url = `/api/practices/?dry_run=${dryRun ? 'True' : 'False'}`
    const method = 'POST'
    const toError = (resp: Response, body: any) => plainToClass(PracticeSignupError, body)
    const result = await this.makeRequest({ method, url, data, toError })
    return result as PracticeSignupResult
  }

  async editPractice(data: PracticeProfileForm, practiceId: number) {
    const url = `/api/practices/${practiceId}/`
    const method = 'POST'
    const toError = (resp: Response, body: any) => plainToClass(PracticeProfileError, body)
    return await this.makeRequest({ method, url, data, toError })
  }

  async getPractice(practiceId: number) {
    const url = `/api/practices/${practiceId}`
    const method = 'GET'
    const result = await this.makeRequest({ method, url })
    return plainToClass(Practice, result)
  }

  async listPractices(data?: PracticeSearchForm) {
    const params = buildURLSearchParams(data ?? {})
    const url = `/api/practices/?${params.toString()}`
    const method = 'GET'
    const results: any[] = await this.makeRequest({ method, url })
    return results.map(result => plainToClass(Practice, result))
  }

  async createNurse(data: NurseSignupForm, dryRun: boolean = true) {
    const url = `/api/nurses/?dry_run=${dryRun ? 'True' : 'False'}`
    const method = 'POST'
    const toError = (resp: Response, body: any) => plainToClass(NurseSignupError, body)
    if (data.radius_miles !== undefined) {
      const radius_km = fromMilesToKm(data.radius_miles)
      data = update(data, { radius_km: { $set: radius_km || 20 }, $unset: ['radius_miles'] })
    }
    return await this.makeRequest({ method, url, data, toError })
  }

  async editNurse(data: NurseProfileForm, nurseId: number) {
    const url = `/api/nurses/${nurseId}/`
    const method = 'POST'
    const toError = (resp: Response, body: any) => plainToClass(NurseProfileError, body)
    if (data.radius_miles !== undefined) {
      const radius_km = fromMilesToKm(data.radius_miles)
      data = update(data, { radius_km: { $set: radius_km || 20 }, $unset: ['radius_miles'] })
    }
    return await this.makeRequest({ method, url, data, toError })
  }

  async getNurse(nurseId: number, jobId?: number) {
    const params = buildURLSearchParams({ job_id: jobId })
    const url = `/api/nurses/${nurseId}/?${params.toString()}`
    const method = 'GET'
    const result = await this.makeRequest({ method, url })
    return plainToClass(Nurse, result)
  }

  async listNurses(data?: NurseSearchForm) {
    const params = buildURLSearchParams(data ?? {})
    const url = `/api/nurses/?${params.toString()}`
    const method = 'GET'
    const results: any[] = await this.makeRequest({ method, url })
    return results.map(result => plainToClass(Nurse, result))
  }

  async addJob(data: AddJobForm) {
    const url = '/api/jobs/'
    const method = 'POST'
    const toError = (resp: Response, body: any) => plainToClass(AddJobError, body)
    const result = await this.makeRequest({ method, url, data, toError })
    return Number(result.id)
  }

  async editJob(jobId: number, data: EditJobForm) {
    const url = `/api/jobs/${jobId}/`
    const method = 'POST'
    const toError = (resp: Response, body: any) => plainToClass(EditJobError, body)
    const result = await this.makeRequest({ method, url, data, toError })
    return Number(result.id)
  }

  async cancelJob(jobId: number, data: JobCancelForm) {
    const url = `/api/jobs/${jobId}/`
    const method = 'DELETE'
    const toError = (resp: Response, body: any) => plainToClass(JobCancelError, body)
    await this.makeRequest({ method, url, data, toError })
  }

  async getJob(jobId: number) {
    const url = `/api/jobs/${jobId}`
    const method = 'GET'
    const result = await this.makeRequest({ method, url })
    return plainToClass(Job, result)
  }

  async publishJobs(practiceId: number) {
    const url = `/api/jobs/publish/${practiceId}/`
    const method = 'POST'
    return await this.makeRequest({ method, url })
  }

  async vetPractice(practiceId: number) {
    const url = `/api/practices/${practiceId}/vetted/`
    const method = 'POST'
    return await this.makeRequest({ method, url })
  }

  async listJobs(data?: JobSearchForm) {
    const params = buildURLSearchParams(data)
    const url = `/api/jobs/?${params.toString()}`
    const method = 'GET'
    const results: any[] = await this.makeRequest({ method, url })
    return results.map(result => plainToClass(Job, result))
  }

  async addJobEmployment(data: TakeJobForm) {
    const url = '/api/jobs/employment/'
    const method = 'POST'
    const toError = (resp: Response, body: any) => plainToClass(TakeJobError, body)
    const result = await this.makeRequest({ method, url, data, toError })
    return Number(result.id)
  }

  async cancelJobEmployment(jeId: number, data: JobEmploymentCancelForm) {
    const url = `/api/jobs/employment/${jeId}/`
    const method = 'DELETE'
    const toError = (resp: Response, body: any) => plainToClass(JobEmploymentCancelError, body)
    return await this.makeRequest({ method, url, data, toError })
  }

  async updateJobEmployment(jeId: number, data: JobEmploymentUpdateForm) {
    const url = `/api/jobs/employment/${jeId}/`
    const method = 'POST'
    const toError = (resp: Response, body: any) => plainToClass(JobEmploymentUpdateError, body)
    return await this.makeRequest({ method, url, data, toError })
  }

  async getJobEmployment(jeId: number) {
    const url = `/api/jobs/employment/${jeId}`
    const method = 'GET'
    const result = await this.makeRequest({ method, url })
    return plainToClass(JobEmployment, result)
  }

  async listJobEmployments(data?: JobEmploymentSearchForm) {
    const params = buildURLSearchParams(data || {})
    const url = `/api/jobs/employment/?${params.toString()}`
    const method = 'GET'
    const results: any[] = await this.makeRequest({ method, url })
    return results.map(result => plainToClass(JobEmployment, result))
  }

  async listBillingAccounts(data?: BillingAccountSearchForm) {
    const params = buildURLSearchParams(data || {})
    const url = `/api/payments/billing_account/?${params.toString()}`
    const method = 'GET'
    const results: any[] = await this.makeRequest({ method, url })
    return results.map(result => plainToClass(BillingAccount, result))
  }

  async getBillingAccount(billingAccountId: number) {
    const url = `/api/payments/billing_account/${billingAccountId}/`
    const method = 'GET'
    const result = await this.makeRequest({ method, url })
    return plainToClass(BillingAccount, result)
  }

  async createBillingAccount(data?: BillingAccountForm) {
    const url = `/api/payments/billing_account/`
    const method = 'POST'
    const toError = (resp: Response, body: any) => plainToClass(BillingAccountFormError, body)
    const result = await this.makeRequest({ method, url, data, toError })
    return result as SetupBillingAccountResponse
  }

  async updateBillingAccount(billingAccountId: number, data?: BillingAccountForm) {
    const url = `/api/payments/billing_account/${billingAccountId}/`
    const method = 'POST'
    const toError = (resp: Response, body: any) => plainToClass(BillingAccountFormError, body)
    const result = await this.makeRequest({ method, url, data, toError })
    return result as SetupBillingAccountResponse
  }

  async linkBillingAccount(data?: BillingAccountLinkForm) {
    const url = '/api/payments/billing_account_link/'
    const method = 'POST'
    const toError = (resp: Response, body: any) => plainToClass(BillingAccountLinkFormError, body)
    return await this.makeRequest({ method, url, data, toError })
  }

  async listPaymentMethods(data?: PaymentMethodSearchForm) {
    const params = buildURLSearchParams(data || {})
    const url = `/api/payments/?${params.toString()}`
    const method = 'GET'
    const results: any[] = await this.makeRequest({ method, url })
    return results.map(result => plainToClass(PaymentMethod, result))
  }

  async getPaymentMethod(pmId: number) {
    const url = `/api/payments/${pmId}/`
    const method = 'GET'
    const result = await this.makeRequest({ method, url })
    return plainToClass(PaymentMethod, result)
  }

  async getStripeDashboardURL(nurseId: number) {
    const url = `/api/payments/dashboard/${nurseId}`
    const method = 'GET'
    const result = await this.makeRequest({ method, url })
    return result.url as string
  }

  async getStripeConnectURL(nurseId: number) {
    const url = `/api/payments/connect/${nurseId}/`
    const method = 'POST'
    const result = await this.makeRequest({ method, url })
    return result.url as string
  }

  async getNurseVerificationSession(nurseId: number) {
    const url = `/api/nurses/${nurseId}/verification-session/`
    const method = 'POST'
    const result = await this.makeRequest({ method, url })
    return result.client_secret as string
  }

  async getNurseBalance(nurseId: number) {
    const url = `/api/payments/available_balance/${nurseId}/`
    const method = 'GET'
    const result = await this.makeRequest({ method, url })
    return result.balance as number
  }

  async listNursePayouts(nurseId: number) {
    const url = `/api/payments/pending_payouts/${nurseId}/`
    const method = 'GET'
    const results: any[] = await this.makeRequest({ method, url })
    return results.map(result => plainToClass(NursePayout, result))
  }

  async listNurseTransactions(nurseId: number) {
    const url = `/api/payments/balance_transactions/${nurseId}/`
    const method = 'GET'
    const results: any[] = await this.makeRequest({ method, url })
    return results.map(result => plainToClass(NurseTransaction, result))
  }

  async exportNurseTransactions(nurseId: number) {
    const params = buildURLSearchParams({ export: true })
    const url = `/api/payments/balance_transactions/${nurseId}/?${params.toString()}`
    const method = 'GET'
    return await this.makeRequest({ method, url })
  }

  async listPracticeTransactions(practiceId: number) {
    const url = `/api/payments/payment_history/${practiceId}/`
    const method = 'GET'
    const results: any[] = await this.makeRequest({ method, url })
    return results.map(result => plainToClass(PracticeTransaction, result))
  }

  async listPracticeGroupTransactions(practiceGroupId: number, data: PracticeGroupTransactionSearchForm) {
    const params = buildURLSearchParams(data || {})
    const url = `/api/payments/group_payment_history/${practiceGroupId}/?${params.toString()}`
    const method = 'GET'
    const results: any[] = await this.makeRequest({ method, url })
    return results.map(result => plainToClass(PracticeGroupTransaction, result))
  }

  async exportPracticeTransactions(practiceId: number) {
    const params = buildURLSearchParams({ export: true })
    const url = `/api/payments/payment_history/${practiceId}/?${params.toString()}`
    const method = 'GET'
    return await this.makeRequest({ method, url })
  }

  async listNurseAchievements(nurseId: number) {
    const url = `/api/nurses/${nurseId}/achievements/`
    const method = 'GET'
    const results: any[] = await this.makeRequest({ method, url })
    return results.map(result => plainToClass(Achievement, result))
  }

  async updateNurseAchievements(nurseId: number) {
    // recalculate the cached paygrades in the Nurse model.
    const url = `/api/nurses/${nurseId}/achievements/`
    const method = 'POST'
    return await this.makeRequest({ method, url })
  }

  async getEarningsReport(nurseId: number, referenceHourlyRate: number) {
    const params = new URLSearchParams()
    params.append('rate', referenceHourlyRate.toString())
    const url = `/api/nurses/${nurseId}/earnings/?${params.toString()}`
    const method = 'GET'
    const result = await this.makeRequest({ method, url })
    return plainToClass(EarningsReport, result)
  }

  async getSavingsReport(practiceId: number, referenceHourlyRate: number) {
    const params = new URLSearchParams()
    params.append('rate', referenceHourlyRate.toString())
    const url = `/api/practices/${practiceId}/spendings/?${params.toString()}`
    const method = 'GET'
    const result = await this.makeRequest({ method, url })
    return plainToClass(SavingsReport, result)
  }

  async getNurseSearchOptions() {
    const url = `/api/nurses/search-options/`
    const method = 'GET'
    return await this.makeRequest({ method, url }) as NurseSearchOptions
  }

  async enableNurses(data: NurseEnableForm) {
    const url = '/api/nurses/enable/'
    const method = 'POST'
    return await this.makeRequest({ method, url, data })
  }

  async enablePractices(data: PracticeEnableForm) {
    const url = '/api/practices/enable/'
    const method = 'POST'
    return await this.makeRequest({ method, url, data })
  }

  async createDocument(data: DocumentForm) {
    const url = '/api/nurses/documents/'
    const method = 'POST'
    const toError = (resp: Response, body: any) => plainToClass(DocumentError, body)
    return await this.makeRequest({ method, url, data, toError })
  }

  async deleteDocument(documentId: number) {
    const url = `/api/nurses/documents/${documentId}/`
    const method = 'DELETE'
    return await this.makeRequest({ method, url })
  }

  async verifyDocument(nurseId: number, data: VerifyDocumentForm) {
    const url = `/api/nurses/${nurseId}/document-approval/`
    const method = 'POST'
    const toError = (resp: Response, body: any) => plainToClass(VerifyDocumentError, body)
    return await this.makeRequest({ method, url, data, toError })
  }

  async autoVerifyDocument(nurseId: number, docType: DocumentType) {
    const url = `/api/nurses/${nurseId}/document-auto-approval/${docType}/`
    const method = 'POST'
    return await this.makeRequest({ method, url })
  }

  async downloadGDCScreenshot(nurseId: number) {
    const url = `/api/nurses/${nurseId}/gdc-screenshot/`
    const method = 'POST'
    return await this.makeRequest({ method, url })
  }

  async getDocument(documentId: number) {
    const url = `/api/nurses/documents/${documentId}`
    const method = 'GET'
    const result = await this.makeRequest({ method, url })
    return plainToClass(NurseDocumentFile, result)
  }

  async listDocuments(data?: NurseDocumentSearchForm) {
    const params = buildURLSearchParams(data)
    const url = `/api/nurses/documents/?${params.toString()}`
    const method = 'GET'
    const results: any[] = await this.makeRequest({ method, url })
    return results.map(result => plainToClass(NurseDocumentFile, result))
  }

  async sendCustomEmail(data: CustomEmailForm) {
    const url = '/api/notifications/custom-email/'
    const method = 'POST'
    const toError = (resp: Response, body: any) => plainToClass(CustomEmailError, body)
    return await this.makeRequest({ method, url, data, toError })
  }

  async listQueuedEmails(data?: QueuedEmailSearchForm) {
    const params = buildURLSearchParams(data)
    const url = `/api/notifications/queued-email/?${params.toString()}`
    const method = 'GET'
    const results: any[] = await this.makeRequest({ method, url })
    return results.map(result => plainToClass(QueuedEmail, result))
  }

  async listTimeSheetChanges(data?: TimeSheetChangeSearchForm) {
    const params = buildURLSearchParams(data)
    const url = `/api/timesheets/?${params.toString()}`
    const method = 'GET'
    const results: any[] = await this.makeRequest({ method, url })
    return results.map(result => plainToClass(TimeSheetChange, result))
  }

  async getTimeSheetChange(tcId: number) {
    const url = `/api/timesheets/${tcId}/`
    const method = 'GET'
    const result = await this.makeRequest({ method, url })
    return plainToClass(TimeSheetChange, result)
  }

  async createTimeSheetChange(data: TimeSheetChangeForm) {
    const url = '/api/timesheets/'
    const method = 'POST'
    const toError = (resp: Response, body: any) => plainToClass(TimeSheetChangeError, body)
    return await this.makeRequest({ method, url, data, toError })
  }

  async updateTimeSheetChange(tcId: number, data: TimeSheetChangeForm) {
    const url = `/api/timesheets/${tcId}/`
    const method = 'POST'
    const toError = (resp: Response, body: any) => plainToClass(TimeSheetChangeError, body)
    return await this.makeRequest({ method, url, data, toError })
  }

  async deleteTimeSheetChange(tcId: number) {
    const url = `/api/timesheets/${tcId}/`
    const method = 'DELETE'
    return await this.makeRequest({ method, url })
  }

  async approveTimeSheetChange(tcId: number) {
    const url = `/api/timesheets/approval/${tcId}/`
    const method = 'POST'
    const data = { action: 'approve' }
    return await this.makeRequest({ method, url, data })
  }

  async disapproveTimeSheetChange(tcId: number) {
    const url = `/api/timesheets/approval/${tcId}/`
    const method = 'POST'
    const data = { action: 'reject' }
    return await this.makeRequest({ method, url, data })
  }

  async getNursePaygradeStats() {
    const url = '/api/nurses/paygrade-stats/'
    const method = 'GET'
    const result = await this.makeRequest({ method, url })
    return result as NursePaygradeStats
  }

  async getEstimatedCost(practiceId: number, data: EstimateCostForm) {
    const url = `/api/jobs/estimate_cost/${practiceId}/`
    const method = 'POST'
    const toError = (resp: Response, body: any) => plainToClass(EstimateCostError, body)
    const result = await this.makeRequest({ method, url, data, toError })
    return result as EstimateCost
  }

  async listAffiliates(data: AffiliateSearchForm) {
    const params = buildURLSearchParams(data ?? {})
    const url = `/api/affiliates/?${params.toString()}`
    const method = 'GET'
    const results: any[] = await this.makeRequest({ method, url })
    return results.map(result => plainToClass(Affiliate, result))
  }

  async getAffiliate(affiliateId: number) {
    const url = `/api/affiliates/${affiliateId}/`
    const method = 'GET'
    const result = await this.makeRequest({ method, url })
    return plainToClass(Affiliate, result)
  }

  async listAffiliateTransactions(data?: AffiliateTransactionSearchForm) {
    const params = buildURLSearchParams(data ?? {})
    const url = `/api/affiliates/transactions/?${params.toString()}`
    const method = 'GET'
    const results: any[] = await this.makeRequest({ method, url })
    return results.map(result => plainToClass(JobEmployment, result))
  }

  async getAffiliateStripeConnectLink(affiliateId: number) {
    const url = `/api/affiliates/${affiliateId}/stripe-connect/`
    const method = 'POST'
    const result = await this.makeRequest({ method, url })
    return result.url as string
  }

  async getAffiliateStripeDashboardLink(affiliateId: number) {
    const url = `/api/affiliates/${affiliateId}/stripe-dashboard/`
    const method = 'GET'
    const result = await this.makeRequest({ method, url })
    return result.url as string
  }

  async createNurseAffiliate(nurseId: number) {
    const url = `/api/affiliates/nurse-affiliate-create/${nurseId}/`
    const method = 'POST'
    const result = await this.makeRequest({ method, url })
    return plainToClass(Affiliate, result)
  }

  async submitReview(employmentId: number, data: SubmitReviewForm) {
    const url = `/api/jobs/submit_review/${employmentId}/`
    const method = 'POST'
    const toError = (resp: Response, body: any) => plainToClass(SubmitReviewError, body)
    return await this.makeRequest({ method, url, data, toError })
  }

  async listBlacklistNurse(data?: BlacklistedNurseSearchForm) {
    const params = buildURLSearchParams(data ?? {})
    const url = `/api/practices/blacklist/?${params.toString()}`
    const method = 'GET'
    const results: any[] = await this.makeRequest({ method, url })
    return results.map(result => plainToClass(BlacklistedNurse, result))
  }

  async addBlacklistNurse(data?: BlacklistNurseForm) {
    const url = '/api/practices/blacklist/'
    const method = 'POST'
    return await this.makeRequest({ method, url , data })
  }

  async deleteBlacklistNurse(blacklistNurseId: number) {
    const url = `/api/practices/blacklist/${blacklistNurseId}/`
    const method = 'DELETE'
    return await this.makeRequest({ method, url })
  }

  async listInvitations(data?: InvitationSearchForm) {
    const params = buildURLSearchParams(data ?? {})
    const url = `/api/practices/invitations/?${params.toString()}`
    const method = 'GET'
    const results: any[] = await this.makeRequest({ method, url })
    return results.map(result => plainToClass(Invitation, result))
  }

  async createInvitation(data: InvitationForm) {
    const url = `/api/practices/invitations/`
    const method = 'POST'
    const toError = (resp: Response, body: any) => plainToClass(InvitationFormError, body)
    return await this.makeRequest({ method, url, data, toError })
  }

  async getInvitation(code: string) {
    const url = `/api/practices/invitations/${code}/`
    const method = 'GET'
    const result = await this.makeRequest({ method, url })
    return plainToClass(Invitation, result)
  }

  async deleteInvitation(code: string) {
    const url = `/api/practices/invitations/${code}/`
    const method = 'DELETE'
    return await this.makeRequest({ method, url })
  }

  async acceptInvitation(data: AcceptInvitationForm, dryRun: boolean) {
    const url = `/api/practices/invitations-accept/?dry_run=${dryRun ? 'True': 'False'}`
    const method = 'POST'
    const toError = (resp: Response, body: any) => plainToClass(AcceptInvitationFormError, body)
    return await this.makeRequest({ method, url, data, toError })
  }

  async listPracticeMembers(data?: PracticeEmploymentSearchForm) {
    const params = buildURLSearchParams(data ?? {})
    const url = `/api/practices/employments/?${params.toString()}`
    const method = 'GET'
    const results: any[] = await this.makeRequest({ method, url })
    return results.map(result => plainToClass(PracticeMember, result))
  }
  
  async getPracticeMember(practiceMemberId: number) {
    const url = `/api/practices/employments/${practiceMemberId}/`
    const method = 'GET'
    const result = await this.makeRequest({ method, url })
    return plainToClass(PracticeMember, result)
  }

  async editPracticeMember(practiceMemberId: number, data: PracticeEmploymentForm) {
    const url = `/api/practices/employments/${practiceMemberId}/`
    const method = 'POST'
    const toError = (resp: Response, body: any) => plainToClass(PracticeEmploymentFormError, body)
    return await this.makeRequest({ method, url, data, toError })
  }

  async deletePracticeMember(practiceMemberId: number) {
    const url = `/api/practices/employments/${practiceMemberId}/`
    const method = 'DELETE'
    return await this.makeRequest({ method, url })
  }

  async listPracticeGroup(data?: PracticeGroupSearchForm) {
    const params = buildURLSearchParams(data ?? {})
    const url = `/api/practice_groups/?${params.toString()}`
    const method = 'GET'
    const results: any[] = await this.makeRequest({ method, url })
    return results.map(result => plainToClass(PracticeGroup, result))
  }

  async getPracticeGroup(practiceGroupId: number) {
    const url = `/api/practice_groups/${practiceGroupId}/`
    const method = 'GET'
    const result = await this.makeRequest({ method, url })
    return plainToClass(PracticeGroup, result)
  }

  async createPracticeGroupPractice(data?: PracticeGroupPracticeForm) {
    const url = `/api/practice_groups/practices/`
    const method = 'POST'
    const toError = (resp: Response, body: any) => plainToClass(PracticeGroupPracticeError, body)
    return await this.makeRequest({ method, url, data, toError })
  }

  async getNurseRightToWorkDocumentLink(nurseId: number) {
    const url = `/api/nurses/${nurseId}/rtw-document-link/`
    const method = 'GET'
    const result = await this.makeRequest({ method, url })
    return result as NurseRightToWorkDocumentLink
  }

  async declineNurseDocuments(jeId: number) {
    const url = `/api/jobs/decline_documents/${jeId}/`
    const method = 'POST'
    const conversationId = await this.makeRequest({ method, url })
    return conversationId as Number
  }

  async chargeNurseLateCancelFee(jeId: number, data: ChargeNurseLateCancelFeeForm) {
    const url = `/api/jobs/charge_nurse_late_fee/${jeId}/`
    const method = 'POST'
    const toError = (resp: Response, body: any) => plainToClass(ChargeNurseLateCancelFeeError, body)
    return await this.makeRequest({ method, url, data, toError })
  }

  async getNurseLocationReport(data?: NurseLocationReportSearchForm) {
    const params = buildURLSearchParams(data ?? {})
    const url = `/api/reports/nurse-location/?${params.toString()}`
    const method = 'GET'
    const result = await this.makeRequest({ method, url })
    return result as NurseLocationReportItem[]
  }

  async getPracticeReport(data?: PracticeReportSearchForm) {
    const params = buildURLSearchParams(data ?? {})
    const url = `/api/reports/practice/?${params.toString()}`
    const method = 'GET'
    const result = await this.makeRequest({ method, url })
    return result as PracticeReportItem[]
  }

  async getJobFulfillmentChartData(data?: ChartSearchForm) {
    const params = buildURLSearchParams(data ?? {})
    const url = `/api/reports/job-fulfillment/?${params.toString()}`
    const method = 'GET'
    const result = await this.makeRequest({ method, url })
    return result as JobChartDataItem[]
  }

  async getMatchCancellationChartData(data?: ChartSearchForm) {
    const params = buildURLSearchParams(data ?? {})
    const url = `/api/reports/match-cancellation/?${params.toString()}`
    const method = 'GET'
    const result = await this.makeRequest({ method, url })
    return result as MatchChartDataItem[]
  }

  async getEarningsChartData(data?: ChartSearchForm) {
    const params = buildURLSearchParams(data ?? {})
    const url = `/api/reports/earnings/?${params.toString()}`
    const method = 'GET'
    const result = await this.makeRequest({ method, url })
    return result as EarningsChartDataItem[]
  }

  async getJobCancellationReplacementChartData(data?: ChartSearchForm) {
    const params = buildURLSearchParams(data ?? {})
    const url = `/api/reports/job-cancellation-replacement/?${params.toString()}`
    const method = 'GET'
    const result = await this.makeRequest({ method, url })
    return result as JobCancellationChartDataItem[]
  }

  async getNurseTierChartData(data?: ChartSearchForm) {
    const params = buildURLSearchParams(data ?? {})
    const url = `/api/reports/nurse-tier/?${params.toString()}`
    const method = 'GET'
    const result = await this.makeRequest({ method, url })
    return result as NurseTierChartDataItem[]
  }

  async getMatchFulfillmentSpeedChartData(data?: ChartSearchForm) {
    const params = buildURLSearchParams(data ?? {})
    const url = `/api/reports/match-fulfillment-speed/?${params.toString()}`
    const method = 'GET'
    const result = await this.makeRequest({ method, url })
    return result as MatchFulfillmentSpeedChartDataItem[]
  }

  async getMatchNurseTierChartData(data?: ChartSearchForm) {
    const params = buildURLSearchParams(data ?? {})
    const url = `/api/reports/match-nurse-tier/?${params.toString()}`
    const method = 'GET'
    const result = await this.makeRequest({ method, url })
    return result as MatchNurseTierChartDataItem[]
  }

  async getJobUrgencyChartData(data?: ChartSearchForm) {
    const params = buildURLSearchParams(data ?? {})
    const url = `/api/reports/job-urgency/?${params.toString()}`
    const method = 'GET'
    const result = await this.makeRequest({ method, url })
    return result as JobUrgencyChartDataItem[]
  }

  async getMatchCorporateChartData(data?: ChartSearchForm) {
    const params = buildURLSearchParams(data ?? {})
    const url = `/api/reports/match-corporate/?${params.toString()}`
    const method = 'GET'
    const result = await this.makeRequest({ method, url })
    return result as MatchCorporateChartDataItem[]
  }

  async listPracticeGroupMembers(data: PracticeGroupMemberSearchForm) {
    const params = buildURLSearchParams(data ?? {})
    const url = `/api/practice_groups/members/?${params.toString()}`
    const method = 'GET'
    const results: any[] = await this.makeRequest({ method, url })
    return results.map(result => plainToClass(PracticeGroupMember, result))
  }

  async nurseWaitlist(data: NurseWaitlistForm) {
    const url = `/api/nurses/waitlist/`
    const method = 'POST'
    const toError = (resp: Response, body: any) => plainToClass(NurseWaitlistFormError, body)
    return await this.makeRequest({ method, url, data, toError })
  }

  async getPracticeSignupStageChartData(data?: ChartSearchForm) {
    const params = buildURLSearchParams(data ?? {})
    const url = `/api/reports/practice-signup-stage/?${params.toString()}`
    const method = 'GET'
    const result = await this.makeRequest({ method, url })
    return result as PracticeSignupStageChartDataItem[]
  }

  async listInvoices(data?: InvoiceSearchForm) {
    const params = buildURLSearchParams(data ?? {})
    const url = `/api/payments/invoice/?${params.toString()}`
    const method = 'GET'
    const results: any[] = await this.makeRequest({ method, url })
    return results.map(result => plainToClass(Invoice, result))
  }

  async listRefunds(data?: RefundSearchForm) {
    const params = buildURLSearchParams(data ?? {})
    const url = `/api/payments/refund/?${params.toString()}`
    const method = 'GET'
    const results: any[] = await this.makeRequest({ method, url })
    return results.map(result => plainToClass(Refund, result))
  }

  async getRefund(refundId: number) {
    const url = `/api/payments/refund/${refundId}/`
    const method = 'GET'
    const result = await this.makeRequest({ method, url })
    return plainToClass(Refund, result)
  }

  async createRefund(data: RefundForm) {
    const url = `/api/payments/refund/`
    const method = 'POST'
    const toError = (resp: Response, body: any) => plainToClass(RefundFormError, body)
    const result = await this.makeRequest({ method, url, data, toError })
    return plainToClass(Refund, result)
  }

  async updateRefund(refundId: number, data: RefundForm) {
    const url = `/api/payments/refund/${refundId}/`
    const method = 'POST'
    const toError = (resp: Response, body: any) => plainToClass(RefundFormError, body)
    const result = await this.makeRequest({ method, url, data, toError })
    return plainToClass(Refund, result)
  }

  async deleteRefund(refundId: number) {
    const url = `/api/payments/refund/${refundId}/`
    const method = 'DELETE'
    return await this.makeRequest({ method, url })
  }

  async listAdditionalCharges(data?: AdditionalChargeSearchForm) {
    const params = buildURLSearchParams(data ?? {})
    const url = `/api/payments/additional_charge/?${params.toString()}`
    const method = 'GET'
    const results: any[] = await this.makeRequest({ method, url })
    return results.map(result => plainToClass(AdditionalCharge, result))
  }

  async getAdditionalCharge(additionalChargeId: number) {
    const url = `/api/payments/additional_charge/${additionalChargeId}/`
    const method = 'GET'
    const result = await this.makeRequest({ method, url })
    return plainToClass(AdditionalCharge, result)
  }

  async createAdditionalCharge(data: AdditionalChargeForm) {
    const url = `/api/payments/additional_charge/`
    const method = 'POST'
    const toError = (resp: Response, body: any) => plainToClass(AdditionalChargeFormError, body)
    const result = await this.makeRequest({ method, url, data, toError })
    return plainToClass(AdditionalCharge, result)
  }

  async updateAdditionalCharge(additionalChargeId: number, data: AdditionalChargeForm) {
    const url = `/api/payments/additional_charge/${additionalChargeId}/`
    const method = 'POST'
    const toError = (resp: Response, body: any) => plainToClass(AdditionalChargeFormError, body)
    const result = await this.makeRequest({ method, url, data, toError })
    return plainToClass(AdditionalCharge, result)
  }

  async deleteAdditionalCharge(additionalChargeId: number) {
    const url = `/api/payments/additional_charge/${additionalChargeId}/`
    const method = 'DELETE'
    return await this.makeRequest({ method, url })
  }

  async getNurseJobsByDate(nurse_id: number, date: DateTime) {
    const url = `/api/jobs/nurse_joblist/${nurse_id}/${date.year}/${date.month}/${date.day}/`
    const method = 'GET'
    const results: any[] = await this.makeRequest({ method, url })
    return results.map(result => plainToClass(Job, result))
  }

  async cancelJobEmploymentByAdmin(jeId: number) {
    const url = `/api/jobs/employment/${jeId}/cancel_by_admin/`
    const method = 'POST'
    return await this.makeRequest({ method, url })
  }

  async listInvoiceLateFees(data?: InvoiceLateFeeSearchForm) {
    const params = buildURLSearchParams(data ?? {})
    const url = `/api/payments/invoice_late_fee/?${params.toString()}`
    const method = 'GET'
    const results: any[] = await this.makeRequest({ method, url })
    return results.map(result => plainToClass(InvoiceLateFee, result))
  }

  async issueInvoiceLateFee(data?: IssueInvoiceLateFeeForm) {
    const url = `/api/payments/issue_invoice_late_fees/`
    const method = 'POST'
    const toError = (resp: Response, body: any) => plainToClass(IssueInvoiceLateFeeError, body)
    return await this.makeRequest({ method, url, data, toError })
  }

  async userDomainCheck(email: string) {
    const url = `/api/practice_groups/domain-check/?email=${email}`
    const method = 'GET'
    const result: string = await this.makeRequest({ method, url })
    return result
  }

  async zendeskLogin() {
    const url = `/api/zendesk/login/`
    const method = 'GET'
    const token: string = await this.makeRequest({ method, url })
    return token
  }

  async getUser(userId: number) {
    const url = `/api/users/${userId}/`
    const method = 'GET'
    const result = await this.makeRequest({ method, url })
    return plainToClass(UserInfo, result)
  }

  async listUsers(data?: UserSearchForm) {
    const params = buildURLSearchParams(data ?? {})
    const url = `/api/users/?${params.toString()}`
    const method = 'GET'
    const results: any[] = await this.makeRequest({ method, url })
    return results.map(result => plainToClass(UserInfo, result))
  }

  async createUser(data: UserForm) {
    const url = `/api/users/`
    const method = 'POST'
    const toError = (resp: Response, body: any) => plainToClass(UserFormError, body)
    return await this.makeRequest({ method, url, data, toError })
  }

  async updateUser(userId: number, data: UserForm) {
    const url = `/api/users/${userId}/`
    const method = 'POST'
    const toError = (resp: Response, body: any) => plainToClass(UserFormError, body)
    return await this.makeRequest({ method, url, data, toError })
  }

  async deleteUser(userId: number) {
    const url = `/api/users/${userId}/`
    const method = 'DELETE'
    return await this.makeRequest({ method, url })
  }

  async viewUserClaims(userId: number) {
    const url = `/api/users/${userId}/claims/`
    const method = 'GET'
    const result = await this.makeRequest({ method, url })
    return result as UserClaim
  }

  async listNurseMapPoints(data?: NurseMapPointSearchForm) {
    const params = buildURLSearchParams(data ?? {})
    const url = `/api/nurses/map-points/?${params.toString()}`
    const method = 'GET'
    const results: any[] = await this.makeRequest({ method, url })
    return results.map(result => plainToClass(NurseMapPoint, result))
  }
  
  async generateTelegramCode(userId: number) {
    const url = `/api/telegram/generate-code/${userId}/`
    const method = 'POST'
    const result = await this.makeRequest({ method, url })
    return result as string
  }

  async listCashBalanceTransactions(data: CashBalanceTransactionSearchForm) {
    const params = buildURLSearchParams(data)
    const url = `/api/payments/cash-balance-transactions/?${params.toString()}`
    const method = 'GET'
    const results: any[] = await this.makeRequest({ method, url })
    return results.map(result => plainToClass(CashBalanceTransaction, result))
  }

  async estimatePracticeLateCancellationFee(jobId: number) {
    const url = `/api/jobs/estimate_practice_late_cancellation_fee/${jobId}/`
    const method = 'GET'
    const result = await this.makeRequest({ method, url })
    return result as number
  }
}

export default API