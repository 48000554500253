import { Box } from '@mui/material'
import DescriptionList from 'components/DescriptionList'
import AdminJobDetailContext from 'pages/AdminJobDetail/context'
import React from 'react'

const AdminJobProfile: React.FC = () => {
  const { job } = React.useContext(AdminJobDetailContext)
  
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
      <DescriptionList labelWidth='20%' valueWidth='80%' rowGap={1} data={[{
        label: 'ID',
        value: job.job_id.toString()
      }, {
        label: 'Practice Name',
        value: job.practice_label
      }, {
        label: 'Address',
        value: job.address,
        sx: { whiteSpace: 'pre-line' }
      }, {
        label: 'Inquiry Number',
        value: job.phone_number
      }, {
        label: 'Manager',
        value: job.first_member_label,
      }, {
        label: 'Date',
        value: `${job.date_label(job.practice_tz)} (Starting in ${job.countdown_label()})`
      }, {
        label: 'Time',
        value: job.time_label(job.practice_tz)
      }, {
        label: 'Published At',
        value: job.published_at_label(job.practice_tz) ?? 'Not Published'
      }, {
        label: 'Cancel At',
        value: job.cancel_at_label(job.practice_tz) ?? 'Not Cancelled'
      }, {
        label: 'Headcount',
        value: `${job.headcount} (total), ${job.filled_employment_count} (filled), ${job.outstanding} (outstanding)`
      }, {
        label: 'Lunch Break',
        value: job.lunch_break_label
      }, {
        label: 'Duration',
        value: job.duration_label
      }, {
        label: 'Description',
        value: job.description,
        sx: { whiteSpace: 'pre-line' }
      }, {
        label: 'Late Cancellation Policy',
        value: job.practice_late_cancellation_policy,
      }, {
        label: 'Document Sharing Required',
        value: job.document_sharing_required ? 'Yes': 'No'
      }, {
        label: 'Extended Compliance Required',
        value: job.extended_compliance_required ? 'Yes': 'No'
      }, {
        label: 'Scotland Compliance Required',
        value: job.scotland_compliance_required ? 'Yes': 'No'
      }, {
        label: 'Job opened to good tier?',
        value: job.is_open_to_tier_label('good')
      }, {
        label: 'Job opened to new tier?',
        value: job.is_open_to_tier_label('new')
      }, {
        label: 'Job opened to bad tier?',
        value: job.is_open_to_tier_label('bad')
      }]} />
    </Box>
  )
}

export default AdminJobProfile