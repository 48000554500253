import { Alert, Box, Button, InputAdornment } from '@mui/material'
import { useAPI } from 'contexts/APIProvider'
import { useSnackBarAlert } from 'contexts/SnackBarAlertProvider'
import React from 'react'
import { JobEmploymentUpdateForm } from 'types/interfaces'
import update from 'immutability-helper';
import Loading from 'components/Loading'
import FormField from 'components/FormField'
import CurrencyPoundIcon from '@mui/icons-material/CurrencyPound'
import ErrorList from 'components/ErrorList'
import Nurse from 'logic/Nurse'
import Practice from 'logic/Practice'
import JobEmploymentUpdateError from 'errors/JobEmploymentUpdateError'
import { isEmpty, round } from 'lodash'
import { DateTime } from 'luxon'
import AdminEmploymentDetailContext from 'pages/AdminEmploymentDetail/context'
import { fromCentsToPounds, fromPountsToCents } from 'logic/helpers'
import CheckboxFormField from 'components/CheckboxFormField'

type FormState = 'untouched' | 'touched'
const tz = 'Europe/London'

const AdminEmploymentManageForm: React.FC = () => {
  const { employment, fetchEmployment } = React.useContext(AdminEmploymentDetailContext)
  const [nurse, setNurse] = React.useState<Nurse>()
  const [practice, setPractice] = React.useState<Practice>()
  const [form, setForm] = React.useState<JobEmploymentUpdateForm>({})
  const [formState, setFormState] = React.useState<FormState>('untouched')
  const [error, setError] = React.useState<JobEmploymentUpdateError>()
  const [loading, setLoading] = React.useState<boolean>(false)
  const { showAlert } = useSnackBarAlert()
  const { api } = useAPI()

  const fetchNurseAndPractice = React.useCallback(async () => {
    if (!employment) return
    setNurse(await api.getNurse(employment.nurse_id, employment.job_id))
    setPractice(await api.getPractice(employment.practice_id))
  }, [api, employment])
  
  const initialForm = React.useMemo((): JobEmploymentUpdateForm | null => {
    if (!employment || !nurse || !practice) return null
    return {
      hourly_rate: employment.hourly_rate ?? nurse.paygrade_rate * 100,
      nurse_fees: employment.nurse_fees ?? nurse.earnings_estimate,
      locumloop_fees: employment.locumloop_fees ?? practice.locumloop_fees,
      start_time: employment.start_time(tz),
      end_time: employment.end_time(tz),
      lunch_break: employment.lunch_break.as('minutes'),
      billable_duration: employment.billable_duration.as('minutes'),
      charge_practice_after: employment.charge_practice_after.setZone(tz).toFormat("yyyy-LL-dd HH:mm"),
      custom_status_nurse: employment.custom_status_nurse,
      custom_status_practice: employment.custom_status_practice,
      admin_notes: employment.admin_notes,
      penalty: employment.penalty,
      penalty_note: employment.penalty_note,
    }
  }, [employment, nurse, practice])

  const updateForm = (name: string, value: any) => {
    setForm(update(form, { [name]: { $set: value } }))
    if (error) setError(update(error, { [name]: { $set: [] } }))
    setFormState('touched')
  }

  const updateJob = async () => {
    try {
      setLoading(true)
      await api.updateJobEmployment(employment.id, form)
      await fetchEmployment()
      setError(undefined)
      showAlert('success', 'Job Updated')
    } catch (e: any) {
      if (e instanceof JobEmploymentUpdateError) {
        setError(e)
      } else {
        console.error('unhandled exception', e)
      }
    } finally {
      setLoading(false)
    }
  }

  const calculatedBillableMinutes = React.useMemo(() => {
    if (form.start_time && form.end_time && form.end_time > form.start_time) {
      const start = DateTime.fromFormat(form.start_time, 'HH:mm')
      const end = DateTime.fromFormat(form.end_time, 'HH:mm')
      const lunch = form.lunch_break ?? 0
      return end.diff(start).as('minutes') - lunch
    }
    return null
  }, [form.start_time, form.end_time, form.lunch_break])

  const calculatedNurseFees = React.useMemo(() => {
    if (calculatedBillableMinutes && form.hourly_rate) {
      return round(form.hourly_rate * calculatedBillableMinutes / 60)
    }
    return null
  }, [calculatedBillableMinutes, form.hourly_rate])

  React.useEffect(() => {
    fetchNurseAndPractice()
  }, [fetchNurseAndPractice])
  
  React.useEffect(() => {
    if (isEmpty(form) && initialForm) setForm(initialForm)
  }, [initialForm])

  React.useEffect(() => {
    if (formState === 'touched' && calculatedBillableMinutes !== null && calculatedNurseFees !== null) {
      setForm(update(form, { 
        nurse_fees: { $set: calculatedNurseFees },
        billable_duration: { $set: calculatedBillableMinutes },
      }))
    }
  }, [formState, calculatedBillableMinutes, calculatedNurseFees])

  if (initialForm === null || employment === undefined || nurse === undefined || practice === undefined) {
    return <Loading />
  }
  
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
      <FormField
        name='hourly_rate'
        label='Hourly Rate (current)'
        type='number'
        onChange={(e) => updateForm('hourly_rate', fromPountsToCents(e.target.value))}
        value={fromCentsToPounds(form.hourly_rate)}
        errors={error?.hourly_rate}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <CurrencyPoundIcon />
            </InputAdornment>
          ),
        }}
      />
      <FormField
        name='start_time'
        label='Start Time'
        type='time'
        onChange={(e) => updateForm('start_time', e.target.value)}
        value={form.start_time}
        errors={error?.start_time}
      />
      <FormField
        name='end_time'
        label='End Time'
        type='time'
        onChange={(e) => updateForm('end_time', e.target.value)}
        value={form.end_time}
        errors={error?.end_time}
      />
      <FormField
        name='lunch_break'
        label='Lunch Break'
        type='number'
        onChange={(e) => updateForm('lunch_break', e.target.value)}
        value={form.lunch_break}
        errors={error?.lunch_break}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              Minutes
            </InputAdornment>
          ),
        }}
      />
      <FormField
        name='billable_duration'
        label='Billable Duration (current)'
        type='number'
        onChange={(e) => updateForm('billable_duration', e.target.value)}
        value={form.billable_duration}
        errors={error?.billable_duration}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              Minutes
            </InputAdornment>
          ),
        }}
      />
      <FormField
        name='nurse_fees'
        label='Nurse Fees (current)'
        type='number'
        onChange={(e) => updateForm('nurse_fees', fromPountsToCents(e.target.value))}
        value={fromCentsToPounds(form.nurse_fees)}
        errors={error?.nurse_fees}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <CurrencyPoundIcon />
            </InputAdornment>
          ),
        }}
      />
      <FormField
        name='locumloop_fees'
        label='Locumloop Fees (current)'
        type='number'
        onChange={(e) => updateForm('locumloop_fees', fromPountsToCents(e.target.value))}
        value={fromCentsToPounds(form.locumloop_fees)}
        errors={error?.locumloop_fees}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <CurrencyPoundIcon />
            </InputAdornment>
          ),
        }}
      />
      <FormField
        name='charge_practice_after'
        label='Charge Practice After'
        type='datetime-local'
        onChange={(e) => updateForm('charge_practice_after', e.target.value)}
        value={form.charge_practice_after}
        errors={error?.charge_practice_after}
      />
      <CheckboxFormField
        label='Apply Penalty (for nurse)'
        helperText="This impacts the nurse's tier negatively. Apply if the nurse performs poorly. The system will automatically apply a penalty if the nurse cancels."
        onChange={(e) => updateForm('penalty', e.target.checked)}
        checked={form.penalty ?? false}
        errors={error?.penalty}
      />
      <FormField
        label='Penalty Note (for nurse)'
        name='penalty_note'
        helperText="Reason for the nurse penalty. This will not be displayed to the nurse."
        onChange={(e) => updateForm('penalty_note', e.target.value)}
        value={form.penalty_note ?? ''}
        errors={error?.penalty_note}
      />
      <FormField
        name='custom_status_nurse'
        label='Override Nurse Status'
        multiline
        minRows={4}
        onChange={(e) => updateForm('custom_status_nurse', e.target.value)}
        value={form.custom_status_nurse}
        errors={error?.custom_status_nurse}
      />
      <FormField
        name='custom_status_practice'
        label='Override Practice Status'
        multiline
        minRows={4}
        onChange={(e) => updateForm('custom_status_practice', e.target.value)}
        value={form.custom_status_practice}
        errors={error?.custom_status_practice}
      />
      <FormField
        name='admin_notes'
        label='Admin Notes'
        multiline
        minRows={4}
        onChange={(e) => updateForm('admin_notes', e.target.value)}
        value={form.admin_notes}
        errors={error?.admin_notes}
      />
      {error?.schema ? (
        <Alert severity='error' variant='filled'><ErrorList errors={error.schema} /></Alert>
      ) : null}
      <Button
        variant="outlined"
        color="primary"
        onClick={updateJob}
        disabled={loading}>
        {loading ? 'Please Wait ...' : 'Update Job'}
      </Button>
    </Box>
  )
}

export default AdminEmploymentManageForm