import React from 'react'
import { Link as RouterLink } from 'react-router-dom'
import { isEmpty } from 'lodash';
import { 
  adminPracticeDetailURL,
} from 'routes/urls'
import { useAPI } from 'contexts/APIProvider'
import { Button, Chip, Typography, List, ListItem, MenuItem } from '@mui/material'
import { JobSearchForm } from 'types/interfaces'
import MultiSelect from 'components/MultiSelect'
import FormField from 'components/FormField'
import { 
  DataGridPro, 
  GridColDef, 
  GridRenderCellParams, 
  GridValueGetterParams,
  GridColumnVisibilityModel,
  GridSortModel,
  GridEventListener,
  GridRowId,
} from '@mui/x-data-grid-pro';
import { 
  Link,
  Alert,
  Box,
} from '@mui/material'
import Job from 'logic/Job';
import { Observable } from 'rxjs';
import PracticeGroup from 'logic/PracticeGroup';

type FilterOptions = 
| 'fulfillment_statuses'
| 'payment_statuses'
| 'timesheet_statuses'
| 'from_date'
| 'to_date'
| 'practice'
| 'practice_group'

interface AdminJobTableProps {
  filters?: FilterOptions[]
  reload$?: Observable<boolean>
  actions?: React.ReactNode
  onRowClick?: GridEventListener<"rowClick">
  form: JobSearchForm,
  updateForm: (name: string, value: any) => void
  selection: GridRowId[]
  setSelection: (value: GridRowId[]) => void
  columnVisibilityModel: GridColumnVisibilityModel
  setColumnVisibilityModel: (value: GridColumnVisibilityModel) => void
  sortModel: GridSortModel
  setSortModel: (value: GridSortModel) => void
}

const AdminJobTable: React.FC<AdminJobTableProps> = ({
  filters = [
    'fulfillment_statuses',
    'payment_statuses',
    'timesheet_statuses',
    'from_date',
    'to_date',
    'practice',
    'practice_group',
  ],
  reload$,
  actions,
  onRowClick,
  form,
  updateForm,
  selection,
  setSelection,
  columnVisibilityModel,
  setColumnVisibilityModel,
  sortModel,
  setSortModel,
}) => {
  const { api } = useAPI()
  const [jobs, setJobs] = React.useState<Job[]>([])
  const [practiceGroups, setPracticeGroups] = React.useState<PracticeGroup[]>([])
  const [loading, setLoading] = React.useState<boolean>(false)

  const fetchJobs = React.useCallback(async () => {
    setLoading(true)
    setJobs(await api.listJobs(form))
    setLoading(false)
  }, [api, form])

  const fetchPracticeGroups = React.useCallback(async () => {
    setPracticeGroups(await api.listPracticeGroup())
  }, [api])

  const onSelectionChanged = React.useCallback((selectedIDs: GridRowId[]) => {
    setSelection(selectedIDs)
  }, [setSelection])

  // React.useEffect(() => {
  //   fetchJobs()
  // // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [])

  React.useEffect(() => {
    const sub = reload$?.subscribe(() => fetchJobs())
    return () => sub?.unsubscribe()
  }, [fetchJobs, reload$])

  React.useEffect(() => {
    fetchPracticeGroups()
  }, [fetchPracticeGroups])

  const columns: GridColDef<Job>[] = React.useMemo(() => [{
    minWidth: 50,
    headerName: 'ID',
    field: 'job_id',
    type: 'number',
  } , {
    minWidth: 150,
    headerName: 'Status',
    field: 'fulfillment_status',
    type: 'string',
    renderCell: (params: GridRenderCellParams<Job>) => {
      const job = params.row
      return (
        <Box sx={{ display: 'flex', flexDirection: 'row', gap: 1}}>
          <Chip
            size='small'
            label={job.fulfillment_status}
            color={job.fulfillment_status_color} />
          {job.payment_status ? (
            <Chip
              size='small'
              label={job.payment_status}
              color={job.payment_status_color} />
          ) : null}
          {job.timesheet_status ? (
            <Chip
              size='small'
              label={job.timesheet_status}
              color={job.timesheet_status_color} />
          ) : null}
        </Box>
      )
    }
  }, {
    minWidth: 100,
    headerName: 'Previous Cancellations (by nurse)',
    field: 'cancelled_by_nurse_employment_count',
    type: 'number',
  }, {
    minWidth: 350,
    headerName: 'Job Date',
    field: 'start_at',
    type: 'dateTime',
    valueGetter: (params: GridValueGetterParams<Job>) => {
      return params.row.start_at?.toJSDate()
    },
    renderCell: (params: GridRenderCellParams<Job>) => {
      const job = params.row
      return (
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
          <Typography>In {job.countdown_label()}</Typography>
          <Typography>{job.date_label(job.practice_tz)} ({job.time_label(job.practice_tz)})</Typography>
        </Box>
      )
    }
  }, {
    minWidth: 200,
    headerName: 'Posted At',
    field: 'created_at',
    type: 'dateTime',
    valueGetter: (params: GridValueGetterParams<Job>) => {
      return params.row.created_at?.toJSDate()
    },
    renderCell: (params: GridRenderCellParams<Job>) => {
      const job = params.row
      return <Typography>{job.posted_at_label(job.practice_tz)}</Typography>
    }
  }, {
    minWidth: 200,
    headerName: 'Published At',
    field: 'published_at',
    type: 'dateTime',
    valueGetter: (params: GridValueGetterParams<Job>) => {
      return params.row.published_at?.toJSDate()
    },
    renderCell: (params: GridRenderCellParams<Job>) => {
      const job = params.row
      if (job.published_at) {
        return <Typography>{job.published_at_label(job.practice_tz)}</Typography>
      } else {
        return "Not Published"
      }
    }
  }, {
    minWidth: 200,
    headerName: 'Practice',
    field: 'practice_name',
    type: 'string',
    renderCell: (params: GridRenderCellParams<Job>) => {
      const job = params.row
      return (
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
          <Link typography='body1' component={RouterLink} to={adminPracticeDetailURL(job.practice_id)}>
            {job.practice_name}
          </Link>
        </Box>
      )
    }
  }, {
    minWidth: 200,
    headerName: 'Practice Group',
    field: 'practice_group_name',
    type: 'string',
    valueGetter: (params: GridValueGetterParams<Job>) => {
      return params.row.practice_group_name ?? 'Standalone'
    },
  }, {
    minWidth: 200,
    headerName: 'Payment Option',
    field: 'practice_payment_method_available',
    type: 'boolean',
    renderCell: (params: GridRenderCellParams<Job>) => {
      const job = params.row
      if (!job.practice_payment_method_available) {
        return (
          <Chip
            size='small'
            label='Not Ready to Process Payments!'
            color='error' />
        )
      } else {
        return job.payment_option ?? 'N/A'
      }
    }
  }, {
    minWidth: 200,
    headerName: 'Nurses',
    field: 'nurses',
    type: 'string',
    renderCell: (params: GridRenderCellParams<Job>) => {
      const job = params.row
      return (
        !isEmpty(job.employment_labels) ? (
          <List dense={true} disablePadding={true}>
            {job.employment_labels.map(employment => (
              <ListItem key={employment}>{employment}</ListItem>
            ))}
          </List>
        ) : null
      )
    }
  }, {
    minWidth: 200,
    headerName: 'Fulfillment',
    field: 'fulfillment',
    type: 'string',
    renderCell: (params: GridRenderCellParams<Job>) => {
      const job = params.row
      return (
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
          <Typography>{job.filled_ratio_label}</Typography>
          <Typography>{job.paid_ratio_label}</Typography>
        </Box>
      )
    }
  }], [])

  const fulfillment_statuses = [
    'cancelled', 
    'filled', 
    'partially_filled', 
    'not_filled'
  ]
  const payment_statuses = [
    'practice_payment_pending',
    'practice_payment_success',
    'practice_payment_failed',
    'practice_invoice_finalized',
    'practice_invoice_paid',
    'practice_invoice_void',
  ]
  const timesheet_statuses = [
    'timesheet_pending_approval'
  ]

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1, height: '100%' }}>
      <Box sx={{ display: 'flex', flexDirection: 'row', gap: 1, alignItems: 'center' }}>
        {filters.includes('practice') ? (
          <FormField
            name='practice'
            label='Practice'
            onChange={(e) => updateForm('practice', e.target.value)}
            value={form.practice ?? ''}/>
        ): null}
        {filters.includes('practice_group') ? (
          <FormField
            select
            sx={{ width: 200 }}
            name='practice_group'
            label='Practice Group'
            onChange={(e) => updateForm('practice_group_id', e.target.value)}
            value={form.practice_group_id ?? ''}>
            <MenuItem value=''>Not Specified</MenuItem>
            <MenuItem value='-999'>Standalone</MenuItem>
            {practiceGroups.map((group) => (
              <MenuItem key={group.id} value={group.id}>{group.name}</MenuItem>
            ))}
          </FormField>
        ): null}
        {filters.includes('fulfillment_statuses') ? (
          <MultiSelect
            name='fulfillment_statuses'
            label='Status'
            options={fulfillment_statuses}
            onChange={(e) => updateForm('fulfillment_statuses', e.target.value)}
            value={form.fulfillment_statuses ?? []}
          />
        ): null}
        {filters.includes('payment_statuses') ? (
          <MultiSelect
            name='payment_statuses'
            label='Payment'
            options={payment_statuses}
            onChange={(e) => updateForm('payment_statuses', e.target.value)}
            value={form.payment_statuses ?? []}
          />
        ): null}
        {filters.includes('timesheet_statuses') ? (
          <MultiSelect
            name='timesheet_statuses'
            label='Timesheet'
            options={timesheet_statuses}
            onChange={(e) => updateForm('timesheet_statuses', e.target.value)}
            value={form.timesheet_statuses ?? []}
          />
        ): null}
        {filters.includes('from_date') ? (
          <FormField
            type='date'
            name='from_date'
            label='Start Date'
            onChange={(e) => updateForm('from_date', e.target.value)}
            value={form.from_date ?? ''}/>
        ): null}
        {filters.includes('to_date') ? (
          <FormField
            type='date'
            name='to_date'
            label='To Date'
            onChange={(e) => updateForm('to_date', e.target.value)}
            value={form.to_date ?? ''}/>
        ): null}
        <Button
          variant="contained"
          onClick={fetchJobs}
          disabled={loading}>
          {loading ? 'Please Wait ...' : 'Search'}
        </Button>
        <Box sx={{ flexGrow: 1, display: 'flex', justifyContent: 'flex-end' }}>
          {actions}
        </Box>
      </Box>
      {isEmpty(jobs) ? (
        <Alert severity='info'>No Records Yet</Alert>
      ) : (
        <React.Fragment>
          <Alert severity='info'>{jobs.length} records returned. {selection.length} selected</Alert>
          <DataGridPro 
            rows={jobs}
            columns={columns}
            getRowId={(job) => job.job_id}
            checkboxSelection
            disableRowSelectionOnClick
            rowSelectionModel={selection}
            onRowSelectionModelChange={onSelectionChanged}
            columnVisibilityModel={columnVisibilityModel}
            onColumnVisibilityModelChange={(newModel) => setColumnVisibilityModel(newModel)}
            sortModel={sortModel}
            onSortModelChange={(newModel) => setSortModel(newModel)}
            loading={loading}
            onRowDoubleClick={onRowClick}
            hideFooter
          />
        </React.Fragment>
      )}
    </Box>
  )
}

export default AdminJobTable